import {
  Bars3BottomLeftIcon as MenuAlt2Icon,
  MagnifyingGlassIcon as SearchIcon,
  XMarkIcon as XIcon,
} from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";

import Link from "next/link";
import React, { Fragment } from "react";

import { Logo } from "../lib/constants";

import useMediaQuery from "../hooks/useMediaQuery";
import { cn } from "@/lib/utils";
import { usePathname } from "next/navigation";

const navigation = [
  { name: "About", href: "/about" },
  { name: "Team", href: "/team" },
  { name: "Projects", href: "/projects" },
  { name: "Pursuits", href: "/pursuits" },
  { name: "Live Cams", href: "/live-cams" },
  { name: "Careers", href: "/careers" },
  { name: "Contact", href: "/contact" },
];

function useIsScrolled() {
  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return isScrolled;
}

export default function Navbar() {
  const pathname = usePathname();
  const isPageWide = !useMediaQuery("(max-width: 768px)");
  const isScrolled = useIsScrolled();

  return (
    <>
      <Popover className="bg-white sticky top-0 z-30">
        <div
          className="absolute inset-0 z-30 pointer-events-none"
          aria-hidden="true"
        />
        <div
          className={`relative border-b-2 transition-all ${
            isScrolled ? "border-gray-100" : "border-transparent"
          }`}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              className={`flex justify-between h-16 items-center md:justify-start md:space-x-6 lg:space-x-8`}
            >
              {/* Logo */}
              <div>
                <Link href="/" className="flex">
                  <div className="sr-only">Homepage</div>
                  <Logo className="h-8 sm:h-10 w-auto text-accent" />
                </Link>
              </div>

              <div className="space-x-1 flex xs:space-x-2 md:hidden">
                {navigation.map((link, index) => (
                  <Link
                    key={link.name}
                    href={link.href}
                    className={cn(
                      "text-sm xs:text-base font-medium text-gray-500 hover:text-accent p-2",
                      pathname === link.href ? "text-accent font-semibold" : "",
                      index > 3 ? "hidden md:block" : "",
                      index === 3 ? "hidden sm:block" : ""
                    )}
                  >
                    {link.name}
                  </Link>
                ))}
              </div>

              {/* Desktop Menu */}
              <div className="md:flex-1 md:flex md:items-center md:justify-center">
                <Popover.Group className="flex">
                  <nav className="flex space-x-0 sm:space-x-6 md:space-x-4 lg:space-x-6">
                    {navigation.map((link) => (
                      <Link
                        key={link.name}
                        href={link.href}
                        className={cn(
                          "rounded-md p-2 hidden md:flex text-base font-medium text-gray-500 hover:text-accent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-500",
                          pathname === link.href
                            ? "text-accent font-semibold"
                            : ""
                        )}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </nav>

                  <Popover>
                    {({ open }) => (
                      <>
                        {isPageWide ? null : (
                          <div className="-my-2 -ml-2 md:hidden">
                            <Popover.Button
                              type="button"
                              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-600 hover:bg-gray-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-accent-500"
                            >
                              <span className="sr-only">Open sidebar</span>
                              {open ? (
                                <XIcon
                                  className="h-6 w-6 xs:h-7 xs:w-7"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MenuAlt2Icon
                                  className="h-6 w-6 xs:h-7 xs:w-7"
                                  aria-hidden="true"
                                />
                              )}
                            </Popover.Button>
                          </div>
                        )}

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 -translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0"
                        >
                          <Popover.Panel
                            className={cn(
                              "md:hidden border-gray-100 bg-white border-b-2 absolute z-30 top-full inset-x-0 transform -mt-2 "
                            )}
                          >
                            <div className="relative max-w-[85%] mx-auto">
                              <nav className="flex items-center justify-center pb-2">
                                <ul
                                  role="list"
                                  className="xs:gap-x-2 gap-x-1 md:gap-x-8 gap-y-0 items-center justify-center flex flex-wrap md:flex-row"
                                >
                                  {navigation.slice(-4).map((item, index) => (
                                    <li key={item.name} className="flow-root">
                                      <Popover.Button
                                        as={Link}
                                        href={item.href}
                                        className={cn(
                                          "flex p-2 items-center text-sm xs:text-base rounded-md font-medium text-gray-500 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-accent-500",
                                          index === 0 ? "sm:hidden" : "",
                                          pathname === item.href
                                            ? "text-gray-900"
                                            : ""
                                        )}
                                      >
                                        {item.name}
                                      </Popover.Button>
                                    </li>
                                  ))}
                                </ul>
                              </nav>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </Popover.Group>
              </div>

              {/* Search Icon */}
              <div className="hidden md:flex">
                <Link
                  href="/search"
                  className="-m-2 p-2 text-gray-400 hover:text-accent focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-accent-500 rounded-full"
                >
                  <span className="sr-only">Search</span>
                  <SearchIcon className="w-6 h-6" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
